import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { BackendService } from "../backend.service";
import { Customer } from "../model/customer";

export class CustomerListDataSource extends DataSource<Customer> {
    filter: string

    private dataStream = new Subject<Customer[]>();


    constructor(private backendService: BackendService) {
        super();
    }

    connect(collectionViewer: CollectionViewer): Observable<Customer[] | readonly Customer[]> {
        return this.dataStream
    }

    disconnect(collectionViewer: CollectionViewer): void { }

    init() {
        this.reload()
    }

    reload() {
        const query$ = this.backendService.getCustomers$().pipe(
            map(customers => {
                customers.sort((c1, c2) => c1.name.localeCompare(c2.name))
                return customers.filter(this.customerFilterPredicate.bind(this))
            })
        )
        query$.subscribe(customers => this.dataStream.next(customers))
    }


    private customerFilterPredicate(candidate: Customer): boolean {
        if (!this.filter) return true

        const filterSegments = this.filter.toLowerCase().split(' ');
        const filterString = (candidate.id + candidate.name + candidate.id).toLowerCase();
        for (const filterSegment of filterSegments) {
            if (filterString.includes(filterSegment))
                return true
        }
        return false;
    }

}