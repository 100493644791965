import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { concat, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BackendService } from '../backend.service';
import { ConfirmComponent, ConfirmDialogModel } from '../confirm/confirm.component';
import { DeviceInfo } from '../model/deviceInfo';
import { DeviceDataSource } from './device.datasource';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.sass']
})
export class DeviceListComponent implements OnInit, AfterViewInit {
  dataSource: DeviceDataSource
  displayedColumns = ["select", "name", "status", "uptime", "customer", "address"]
  selection = new SelectionModel<DeviceInfo>(true, []);

  snackBarRef?: MatSnackBarRef<SimpleSnackBar> = null;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private router: Router, private backend: BackendService, public dialog: MatDialog, private snackBar: MatSnackBar) {
    this.dataSource = new DeviceDataSource(backend)
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.selection = this.selection;
    this.dataSource.init();
  }

  ngOnInit() {
  }

  getDocumentsUrl(type: string): string {
    const deviceIdListQueryParams = this.selection.selected.map(d => d.deviceRefId).join("&devices=")
    return `${environment.backendUrl}/device/document?type=${type}&devices=${deviceIdListQueryParams}`
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  clickRow(info: DeviceInfo) {
    this.router.navigateByUrl(`/devices/${info.deviceRefId}`)
  }

  deleteDevices(devices: DeviceInfo[]) {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss()
      this.snackBarRef = null;
    }
    const deviceListDisplay = devices.map(d => `• ${d.name} [${d.deviceMqttId}]`).join('\n');
    const dialogData = new ConfirmDialogModel(`Folgende Geräte löschen?`, deviceListDisplay);
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: dialogData
    });
    dialogRef.afterClosed().pipe(
      mergeMap(result => {
        if (result) {
          const ops = devices.map(d => this.backend.deleteDevice$(d));
          return concat(...ops)
        }
        return EMPTY
      })
    ).subscribe(() => { }, e => this.onDeleteDevicesError(e.message), () => this.dataSource.reload());
  }

  onDeleteDevicesError(msg) {
    this.snackBarRef = this.snackBar.open("Error: " + msg, '❌', { panelClass: 'snack-err' })
  }

  deviceNotes() {
    const devices = this.selection.selected;

    let notes = "Seriennummer, Device-ID:\n"
      + devices.map(d => `${d.serialNr}, ${d.deviceMqttId}`).join('\n')

    navigator.clipboard.writeText(notes);
    this.snackBar.open("Notes copied to clipboard", null, {
      duration: 2000
    })
  }
}