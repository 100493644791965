import { Component, OnInit } from '@angular/core';
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from "../environments/environment";
import { Router, NavigationEnd, ActivationEnd } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  title = 'Comydo Gerätewartung';
  showBack = false
  detailPages = ['/devices', '/devices/new', '/customers/new']

  constructor(public oAuthService: OAuthService, private router: Router, private location: Location) {
    
  }

  onLogout() {
    this.oAuthService.revokeTokenAndLogout().then(() => {
      this.router.navigateByUrl("/login");
    });
  }

  onBack() {
    this.location.back()
  }
  

  ngOnInit(): void {
    this.oAuthService.configure(environment.authConfig)
    this.oAuthService.setupAutomaticSilentRefresh();
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        let showBack = false;
        for (const detailPage of this.detailPages) {
          showBack = showBack || e.url.startsWith(detailPage)
        }
        this.showBack = showBack
      }
    })
  }
}
