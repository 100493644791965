import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { interval, BehaviorSubject, Subscription, of, from, zip, Observable, Subject } from 'rxjs';
import { BackendService } from '../backend.service';
import moment from "moment";
import { StatusPhase } from '../model/statusPhase';
import { DeviceInfo } from '../model/deviceInfo';
import { MatDialog } from '@angular/material/dialog';
import { TimeRangePickerComponent } from '../time-range-picker/time-range-picker.component';
import { environment } from "../../environments/environment";
import { DeviceRealtimeInfo } from '../model/deviceRealtimeInfo';
import { DeviceAlertConfig } from '../model/deviceRuleConfig';
import { map, concatMap, flatMap, toArray } from 'rxjs/operators';
import { ConfirmDialogModel, ConfirmComponent } from '../confirm/confirm.component';
import { DeviceCommand } from '../model/deviceCommand';
import { DeviceCommandDialogComponent } from '../device-command-dialog/device-command-dialog.component';
import { UntypedFormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.sass']
})
export class DeviceComponent implements OnInit, OnDestroy {
  DeviceCommand = DeviceCommand

  constructor(private route: ActivatedRoute, public backend: BackendService, public dialog: MatDialog) { }

  environment = environment

  isRealtime: boolean = true;
  realtimeRangeHours: number = 24;

  startTs: moment.Moment = moment.utc().subtract(this.realtimeRangeHours, 'hours')
  endTs: moment.Moment = moment.utc()


  deviceRefId: string = null
  deviceInfo: DeviceInfo = null
  deviceRealtimeInfo: DeviceRealtimeInfo = null

  deviceAlertConfig: DeviceAlertConfig = new DeviceAlertConfig();
  slackEnabledForm = new UntypedFormControl(false)
  opsgenieEnabledForm = new UntypedFormControl(false)
  isUpdatingAlertConfig = false;

  timelineData: BehaviorSubject<Object[]> = new BehaviorSubject([])

  diagnoseinfo: Subject<string> = new BehaviorSubject("n/a")

  updateIntervalSubscription: Subscription

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.deviceRefId = params.get("uuid")
      this.update();
      this.updateIntervalSubscription = interval(10 * 1000).subscribe(this.update.bind(this));
    });
  }

  ngOnDestroy() {
    this.updateIntervalSubscription.unsubscribe();
  }

  update() {
    if (this.isRealtime) {
      this.updateTimeline();
    }
    this.updateDeviceStatus();
  }

  updateTimeline() {
    if (this.isRealtime) {
      this.startTs = moment.utc().subtract(this.realtimeRangeHours, 'hours');
      this.endTs = moment.utc();
    }
    this.backend.getDeviceTimeline$(this.deviceRefId, this.startTs, this.endTs).subscribe((tlData: StatusPhase[]) => {
      this.timelineData.next(tlData);
    });
  }

  updateDeviceStatus() {
    this.backend.getDeviceInfo$(this.deviceRefId).subscribe((info: DeviceInfo) => {
      this.deviceInfo = info;
    });
    this.backend.getDeviceRealtimeInfo$(this.deviceRefId).subscribe(info => {
      this.deviceRealtimeInfo = info
    });
    this.backend.getDeviceAlertConfig$(this.deviceRefId).subscribe(cfg => {
      this.deviceAlertConfig = cfg
    });
  }


  onAlertConfigSave() {
    this.isUpdatingAlertConfig = true;
    this.backend.updateDeviceAlertConfig$(this.deviceAlertConfig).subscribe(() => this.isUpdatingAlertConfig = false);
  }

  sendCommand(command: DeviceCommand) {
    const dialogRef = this.dialog.open(DeviceCommandDialogComponent, {
      data: {
        deviceCommand: command,
        deviceId: this.deviceRefId
      }
    });
  }

  showSelectTimeRangeDialog() {
    const dialogRef = this.dialog.open(TimeRangePickerComponent, {
      data: {
        isRealtime: this.isRealtime,
        realtimeRangeHours: this.realtimeRangeHours,
        startTs: this.startTs,
        endTs: this.endTs,
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {

        this.isRealtime = data.isRealtime;
        this.realtimeRangeHours = data.realtimeRangeHours;
        this.startTs = data.startTs;
        this.endTs = data.endTs;
        this.updateTimeline();
      }
    });
  }
}
