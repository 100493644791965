import { Pipe, PipeTransform, ElementRef } from '@angular/core';
import { StatusPhase } from '../model/statusPhase';
import moment from "moment";


@Pipe({
  name: 'timelineOffset'
})
export class TimelineOffsetPipe implements PipeTransform {

  transform(phase: StatusPhase, startTs: moment.Moment, endTs: moment.Moment, container: Element): any {
   let phaseStartTs = moment.utc(phase.tsStart);
    let relTime = moment.duration(phaseStartTs.diff(startTs));

    let offset = this.relTimeToOffset(relTime, container, startTs, endTs);

    return offset * 100;
  }


  relTimeToOffset(relTime: moment.Duration, container: Element, startTs: moment.Moment, endTs: moment.Moment): number {
    let fullTimeRange = this.getTimeRange(startTs, endTs).as('seconds');
    let offsetTime = relTime.as('seconds');

    let frac = offsetTime / fullTimeRange;


    return frac;
  }

  getTimeRange(startTs: moment.Moment, endTs: moment.Moment) {
    return moment.duration(endTs.diff(startTs))
  }

}
