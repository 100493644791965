import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { BackendService } from '../backend.service';
import { Customer } from '../model/customer';
import { DeviceFinalizationRequest } from '../model/deviceCreationRequest';

@Component({
  selector: 'app-device-creation-form',
  templateUrl: './device-creation-form.component.html',
  styleUrls: ['./device-creation-form.component.sass']
})
export class DeviceCreationFormComponent implements OnInit, AfterViewInit {
  customers: Customer[] = []
  isSubmitting = false
  snackBarRef?: MatSnackBarRef<SimpleSnackBar> = null;
  deviceRefIdControl = this.formBuilder.control({value: null, disabled: true}, Validators.required)


  deviceForm = this.formBuilder.group({
    name: [null, Validators.required],
    imei: [null, Validators.required],
    iccid: [null, Validators.required],
    simProvider: [null, Validators.required],
    serialNr: [null, Validators.required],
    creationInfo: this.formBuilder.group({
      customerId: [null, Validators.required],
      locks: this.formBuilder.array([])
    })
  });


  get locksControlArray() {
    return this.creationInfo.get("locks") as UntypedFormArray;
  }

  get creationInfo() {
    return this.deviceForm.get("creationInfo") as UntypedFormGroup;
  }

  get canSubmit() {
    return this.deviceForm.valid
  }

  constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, private backend: BackendService, private snackBar: MatSnackBar, private router: Router) {
  }
  ngAfterViewInit(): void {
    this.backend.getCustomers$().subscribe(customers => this.customers = customers);
  }

  addLock() {
    this.locksControlArray.push(this.formBuilder.group({
      activationDuration: [null, [Validators.required, Validators.min(0), Validators.pattern("[0-9]+")]],
      activationDelay: [null, [Validators.required, Validators.min(0), Validators.pattern("[0-9]+")]],
      lockGroupName: [null],
      lockId: [null]
    }));
  }

  deleteLock(lockControl: AbstractControl) {
    const idx = this.locksControlArray.controls.findIndex(c => c === lockControl);
    this.locksControlArray.removeAt(idx);
  }

  getLockControl(name: string, lock: AbstractControl): AbstractControl {
    const l = lock as UntypedFormGroup;
    return l.get(name);
  }

  submit() {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss()
      this.snackBarRef = null;
    }

    this.deviceForm.disable()
    this.isSubmitting = true
    const request = DeviceFinalizationRequest.parseData(this.deviceForm.value);
    
    this.backend.finalizeDevice$(this.deviceRefIdControl.value, request).pipe(
      finalize(() => {
        this.deviceForm.enable()
        this.isSubmitting = false;
      })
    ).subscribe(() => { }, err => this.onDeviceCreationError(err), () => this.onDeviceCreated());
  }

  onDeviceCreated() {
    this.snackBar.open("New device created", '❌', { duration: 2000 })
    this.deviceForm.reset()
    this.router.navigateByUrl("overview/devices")
  }

  onDeviceCreationError(e: any) {
    this.snackBarRef = this.snackBar.open("Error: " + e.message, '❌', { panelClass: 'snack-err' })
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const uuid = params.get('uuid');
      this.deviceRefIdControl.setValue(uuid);
    })
  }

}
