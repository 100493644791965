import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { DeviceInfo } from "../model/deviceInfo";
import { Subject } from 'rxjs';
import moment from "moment";
import { StatusPhase } from '../model/statusPhase';


// TODO: calculate offsets with pipes 
@Component({
  selector: 'timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.sass']
})
export class TimelineComponent implements OnInit {
  constructor() { }
  
  @Input() dataSource: Subject<StatusPhase[]>
  @Input() startTs: moment.Moment = moment().subtract(1, 'day')
  @Input() endTs: moment.Moment = moment()
  
  readonly pretty_ts_format = "DD.MM HH:mm"
  
  
  ngOnInit() {
  }
  
  pretty_print_ts(ts: moment.Moment): string {
    return ts.local().format(this.pretty_ts_format)
  }

  getTooltip(phase: StatusPhase): string {
    let phaseStartTs = this.pretty_print_ts(moment.utc(phase.tsStart))
    let phaseEndTs = "???";
    if (phase.tsEnd) {
      phaseEndTs = this.pretty_print_ts(moment.utc(phase.tsEnd));
    }
    return `[${phaseStartTs}] - [${phaseEndTs}]`;
  }

}
