import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { EMPTY, forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BackendService } from '../backend.service';
import { ConfirmComponent, ConfirmDialogModel } from '../confirm/confirm.component';
import { ProvisioningWorkflow } from '../model/provisioningWorkflow';
import { ProvisioningWorkflowDataSource } from './provisioning-workflow.datasource';
import { environment } from 'src/environments/environment';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-device-provisioning-list',
  templateUrl: './device-provisioning-list.component.html',
  styleUrls: ['./device-provisioning-list.component.sass']
})
export class DeviceProvisioningListComponent implements OnInit, AfterViewInit {

  creationDialogOpen = false;
  newWorkflowsAmount = 1;

  dataSource: ProvisioningWorkflowDataSource;
  selection = new SelectionModel<ProvisioningWorkflow>(true, []);

  snackBarRef?: MatSnackBarRef<SimpleSnackBar> = null;

  constructor(public backend: BackendService, public dialog: MatDialog, private snackBar: MatSnackBar) { 
    this.dataSource = new ProvisioningWorkflowDataSource(backend);
  }

  ngAfterViewInit(): void {
    this.dataSource.init();
  }

  ngOnInit() {
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  createNewWorkflows(count: number) {
    let requests = [];
    for (let i = 0; i < count; i++) {
      requests.push(this.backend.createDeviceProvisioningWorkflow$())
    }
    forkJoin(requests).subscribe({
      complete: () => {
        this.dataSource.reload();
        return this.creationDialogOpen = false;
      }
    })
  }

  deleteWorflow(workflow: ProvisioningWorkflow) {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss()
      this.snackBarRef = null;
    }
    const dialogData = new ConfirmDialogModel(`Folgenden Workflow löschen?`, workflow.deviceId);
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: dialogData
    });
    dialogRef.afterClosed().pipe(
      mergeMap(result => {
        if (result) {
          return this.backend.deleteDevice$(workflow.id)
        }
        return EMPTY
      })
    ).subscribe(() => { }, e => this.onDeleteWorkflowError(e.message), () => this.dataSource.reload());
  }

  getInstallationCardUrl() {
    const deviceIdListQueryParams = this.selection.selected.map(d => d.id).join("&devices=")
    return `${environment.backendUrl}/device/document?type=installation_cards&devices=${deviceIdListQueryParams}`
  }

  

  onDeleteWorkflowError(msg) {
    this.snackBarRef = this.snackBar.open("Error: " + msg, '❌', { panelClass: 'snack-err' })
  }

}
