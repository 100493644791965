import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: number, ...args: any[]): string {
    let seconds = value;

    let minutes = Math.floor(seconds / 60);
    seconds = seconds - (minutes * 60);

    let hours = Math.floor(minutes / 60);
    minutes = minutes - (hours * 60);

    let days = Math.floor(hours / 24);
    hours = hours - (days * 24)

    let result = ""
    if (days) {
      result += `${days} days, `;
    }

    if (hours) {
      result += `${hours} hours, `;
    }

    if (minutes) {
      result += `${minutes} minutes, `;
    }

    if (seconds) {
      result += `${seconds} seconds, `;
    }

    result = result.substr(0, result.length - 2)
    return result;
  }

}
