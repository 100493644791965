// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { AuthConfig } from "angular-oauth2-oidc";

let backendUrl = "https://staging.comydo.com/api/v1"
 
let authConfig: AuthConfig = {
  tokenEndpoint: `${backendUrl}/o/token/`,
  revocationEndpoint: `${backendUrl}/o/revoke_token/`,
  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: '3KHjMHiJHkKZr7w603v6AbJ4bamwvlxCSvfXRnvC',
  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  dummyClientSecret: '4O0hOKKxklq0nc8cMUcxTmdIhFvc8dsjuMOhFzR1mRgJY92mqOvkHPnO4PR3fVdevkCnOOD7d4NWojMrdTdHodQNBgcGB9MKSziDcrmtCzdKWPxJhvwFvy6rcuxEfoib',
  responseType: 'code',
  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: 'read write',
  showDebugInformation: false,
  // Not recommented:
  // disablePKCI: true,
  requireHttps: false
}

export const environment = {
  production: true,
  authConfig: authConfig,
  graylogUrl: "https://graylog.comydo.com",
  backendUrl: backendUrl
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
