import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-log-display',
  templateUrl: './log-display.component.html',
  styleUrls: ['./log-display.component.sass']
})
export class LogDisplayComponent implements OnInit {

  constructor() { }

  @Input("diagnose")
  diagnose: Observable<string>

  ngOnInit() {
  }

}
