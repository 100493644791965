import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OverviewComponent } from './overview/overview.component';
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from "./auth.guard";
import { DeviceComponent } from './device/device.component';
import { DeviceListComponent } from './device-list/device-list.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { MqttLogComponent } from './mqtt-log/mqtt-log.component';
import { DeviceCreationFormComponent } from './device-creation-form/device-creation-form.component';
import { CustomerCreationFormComponent } from './customer-creation-form/customer-creation-form.component';
import { DeviceProvisioningListComponent } from './device-provisioning-list/device-provisioning-list.component';



const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'overview', pathMatch: 'full' },
  { path: 'devices/:uuid/finalize', component: DeviceCreationFormComponent, canActivate: [AuthGuard] },
  { path: 'customers/new', component: CustomerCreationFormComponent, canActivate: [AuthGuard] },
  { path: 'devices/:uuid', component: DeviceComponent, canActivate: [AuthGuard] },
  {
    path: 'overview', component: OverviewComponent, canActivate: [AuthGuard], children: [
      { path: 'devices', component: DeviceListComponent, canActivate: [AuthGuard], },
      { path: 'provisioning', component: DeviceProvisioningListComponent, canActivate: [AuthGuard] },
      { path: 'customers', component: CustomerListComponent, canActivate: [AuthGuard] },
      { path: 'log', component: MqttLogComponent, canActivate: [AuthGuard] },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
