import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections";
import { MatSort } from "@angular/material/sort";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { BackendService } from "../backend.service";
import { DeviceInfo } from "../model/deviceInfo";

export class DeviceDataSource extends DataSource<DeviceInfo> {
    dataStream = new Subject<DeviceInfo[]>();
    sort: MatSort
    filter: string
    data: DeviceInfo[] = []
    selection: SelectionModel<DeviceInfo>

    constructor(private metricService: BackendService) {
        super();

    }
    connect(collectionViewer: CollectionViewer): Observable<DeviceInfo[] | readonly DeviceInfo[]> {
        return this.dataStream
    }
    disconnect(collectionViewer: CollectionViewer): void {
    }

    init() {
        this.sort.sortChange.subscribe(() => this.reload());
        this.reload()
    }

    reload() {
        let query$ = this.metricService.getOverview$().pipe(
            map(devices => {
                devices = devices.filter(d => !this.filter || this.filterDevice(d, this.filter))
                if (this.sort.active && this.sort.direction != "") {
                    devices.sort(this.sortPredicate.bind(this))
                }
                this.data = devices
                this.syncSelection()
                return devices
            })
        );
        query$.subscribe(d => {
            this.dataStream.next(d);
        });
    }

    syncSelection() {
        let newSelection = this.data.filter(dNew => this.selection.selected.filter(dOld => dOld.deviceRefId == dNew.deviceRefId).length);
        this.selection.clear()
        this.selection.select(...newSelection);
    }

    deviceSortDataAccessor(data: DeviceInfo, sortHeaderId: string): string | number {
        switch (sortHeaderId) {
            case "name":
                return data.name
            case "public_id":
                return data.deviceMqttId
            case "status":
                return data.isOnline ? 0 : 1
            case "duration":
                return data.stats.currentPhaseDurationSeconds
            case "uptime":
                return data.stats.uptime24h
            case "statusChanges":
                return data.stats.statechanges24h
            case "address":
                return data.address ? data.address : 0
            case "customer":
                return data.customer ? data.customer.name : 0
            default:
                throw Error("invalid column identifier: " + sortHeaderId)
        }
    }

    filterDevice(device: DeviceInfo, filterStatement: string): boolean {
        let searchstring = this.deviceToSearchString(device);

        filterStatement = filterStatement.toLowerCase()
        searchstring = searchstring.toLowerCase()


        const filterStatementSegments = filterStatement.split(' ')

        for (const filterStatementSegment of filterStatementSegments) {
            if (searchstring.includes(filterStatementSegment)) {
                return true
            }
        }

        return false
    }


    sortPredicate(device1: DeviceInfo, device2: DeviceInfo): number {
        const sortProp1 = this.deviceSortDataAccessor(device1, this.sort.active);
        const sortProp2 = this.deviceSortDataAccessor(device2, this.sort.active);

        let comp = sortProp1 > sortProp2;

        if (this.sort.direction == "asc") {
            comp = !comp;
        }


        if (sortProp1 == sortProp2) {
            return 0
        }
        if (comp) {
            return 1
        }
        return -1
    }

    deviceToSearchString(d: DeviceInfo): string {
        return d.name
            + d.deviceRefId
            + d.deviceMqttId
            + d.simProvider
            + d.serialNr
            + d.imei
            + (d.isOnline ? "online" : "offline")
            + d.address
            + (d.customer ? d.customer.name: "");
    }

}