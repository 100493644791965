import { CollectionViewer, DataSource, SelectionModel } from "@angular/cdk/collections";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { BackendService } from "../backend.service";
import { ProvisioningWorkflow } from "../model/provisioningWorkflow";

export class ProvisioningWorkflowDataSource extends DataSource<ProvisioningWorkflow> {
    dataStream = new Subject<ProvisioningWorkflow[]>();

    data: ProvisioningWorkflow[] = []

    filter: string

    
    constructor(private backend: BackendService) {
        super();
        
    }

    connect(collectionViewer: CollectionViewer): Observable<ProvisioningWorkflow[] | readonly ProvisioningWorkflow[]> {
        return this.dataStream
    }

    disconnect(collectionViewer: CollectionViewer): void {
    }

    init() {
        this.reload()
    }

    reload() {
        const query$ = this.backend.getProvisioningWorkflows$().pipe(
            map(workflows => {
                const filtered = workflows.filter(wf => !this.filter || wf.deviceId.includes(this.filter));
                this.data = filtered;
                return filtered;
            })
        );
        query$.subscribe(workflows => this.dataStream.next(workflows));
    }

}