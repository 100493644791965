import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { BackendService } from '../backend.service';
import { DeviceCommand as DeviceCommand } from '../model/deviceCommand';

@Component({
  selector: 'app-device-command-dialog',
  templateUrl: './device-command-dialog.component.html',
  styleUrls: ['./device-command-dialog.component.sass']
})
export class DeviceCommandDialogComponent implements OnInit {
  deviceCommand: DeviceCommand
  deviceId: string

  responseMessage?: string

  isPending = false;

  get commandName(): string {
    switch (this.deviceCommand) {
      case DeviceCommand.DIAGNOSE:
        return "Diagnose"
      case DeviceCommand.HARD_RESET:
        return "HardReset"
      case DeviceCommand.SCANNER_RESET:
        return "Scanner Reset"
      case DeviceCommand.UUID_RESET:
        return "UUID Reset"
    }
  }

  constructor(public dialogRef: MatDialogRef<DeviceCommandDialogComponent>,
    private backend: BackendService,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    this.deviceCommand = data.deviceCommand
    this.deviceId = data.deviceId
  }

  sendCommand() {
    this.isPending = true;
    this.responseMessage = null;
    this.backend.sendDeviceCommand$(this.deviceId, this.deviceCommand).pipe(
      finalize(() => {
        this.isPending = false
      })
    ).subscribe({
      next: response => {
        this.responseMessage = response.message
      },
      error: err => {
        this.responseMessage = "failed to send command"
      }
    })
  }

  ngOnInit() {
  }

}
