import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { BackendService } from "../backend.service";
import { DeviceInfo } from "../model/deviceInfo";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from "@angular/router";
import { fromEvent } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged, flatMap, map, } from "rxjs/operators";
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogModel, ConfirmComponent } from '../confirm/confirm.component';


class TabLink {
  path: string
  title: string

}

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.sass']
})
export class OverviewComponent implements OnInit, AfterViewInit {
  links: TabLink[] = [
    {path: "devices", title: "Geräte"},
    { path: "provisioning", title: "Gerät Provisionierung"},
    { path: "customers", title: "Kunden" },
    { path: "log", title: "Log" },
  ]
  activeLink: TabLink

  constructor(private router: Router) { 
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (e.urlAfterRedirects == "/overview") {
          router.navigateByUrl("/overview/" + this.links[0].path)
        } else {
          this.activeLink = this.links.filter(l => ("/overview/" + l.path) == e.urlAfterRedirects)[0];
        }
      }
    })
  }


  ngAfterViewInit(): void {
  }

  ngOnInit() {
  }

}
