import { Component, OnInit, Input } from '@angular/core';
import { OAuthService, TokenResponse } from "angular-oauth2-oidc";
import { Router } from "@angular/router";
import { BackendService } from '../backend.service';
import { Observable, from } from "rxjs";
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  @Input() email: string = null
  @Input() password: string = null

  isLoading: boolean = false

  loggedIn$: Observable<boolean>
  snackBarRef?: MatSnackBarRef<SimpleSnackBar>;

  constructor(private oAuthService: OAuthService,
    private router: Router,
    private metricService: BackendService,
    private snackBar: MatSnackBar) {

  }

  ngOnInit() {

  }

  onLoginClicked() {
    this.isLoading = true;
    this.loggedIn$ = new Observable(this.navigateWhenSuperuser.bind(this));
    this.login$(this.email, this.password)
      .subscribe(response => {
        this.isLoading = false;
        this.navigateWhenSuperuser()
      }, err => {
        this.isLoading = false;
        let msg = "Unknown error"
        if (err.status == 0)
          msg = "Backend is offline";
        if (err.status == 400)
          msg = "Invalid credentials";
        return this.errorMessage(msg)
      });
  }

  navigateWhenSuperuser() {
    this.metricService.getUserInfo$().subscribe(info => {
      if (info.permissions.isSuperuser) {
        if (this.snackBarRef) { this.snackBarRef.dismiss() }
        this.router.navigateByUrl("overview")
      } else {
        this.errorMessage("Insufficient Authorization");
        this.oAuthService.logOut()
      }
    }, err => this.errorMessage("Failed to check authorization. Pls contact a dev"))
  }

  login$(user: string, password: string): Observable<TokenResponse> {
    return from(this.oAuthService.fetchTokenUsingPasswordFlow(user, password))
  }

  errorMessage(msg: string) {
    this.snackBarRef = this.snackBar.open(msg, '❌', { panelClass: 'snack-err' })
  }

}
