import { Pipe, PipeTransform } from '@angular/core';
import { StatusPhase } from '../model/statusPhase';

import moment from "moment";

@Pipe({
  name: 'timelineWidth'
})
export class TimelineWidthPipe implements PipeTransform {

  transform(phase: StatusPhase, startTs: moment.Moment, endTs: moment.Moment, container: Element): any {
    let fullTimeRange = this.getTimeRange(startTs, endTs).as('seconds');
    let offset = this.calculateOffset(phase, startTs, endTs, container);

    if (!phase.tsEnd) {
      return 1 - offset;
    }

    let phaseStartTs = moment(phase.tsStart);
    let phaseEndTs = moment(phase.tsEnd);

    let phaseDuration = moment.duration(phaseEndTs.diff(phaseStartTs)).as('seconds');

    let frac = phaseDuration / fullTimeRange;

    return frac * 100;
  }

  getTimeRange(startTs: moment.Moment, endTs: moment.Moment) {
    return moment.duration(endTs.diff(startTs))
  }

  calculateOffset(phase: StatusPhase, startTs: moment.Moment, endTs: moment.Moment, container: Element): any {
    let phaseStartTs = moment.utc(phase.tsStart);
     let relTime = moment.duration(phaseStartTs.diff(startTs));
 
     let offset = this.relTimeToOffset(relTime, container, startTs, endTs);
 
     return offset
  }
  
  relTimeToOffset(relTime: moment.Duration, container: Element, startTs: moment.Moment, endTs: moment.Moment): number {
    let fullTimeRange = this.getTimeRange(startTs, endTs).as('seconds');
    let offsetTime = relTime.as('seconds');

    let frac = offsetTime / fullTimeRange;


    return frac;
  }

}
