// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  padding: 1em;
  background: #292929;
  color: #d6d6d6;
  font-family: Hack, monospace;
  height: 100%;
}

.multiline {
  white-space: pre-wrap;
}`, "",{"version":3,"sources":["webpack://./src/app/device/log-display/log-display.component.sass"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,mBAAA;EACA,cAAA;EACA,4BAAA;EACA,YAAA;AACJ;;AACA;EACI,qBAAA;AAEJ","sourcesContent":[".container\n    padding: 1em\n    background: #292929\n    color: #d6d6d6\n    font-family: Hack, monospace\n    height: 100%\n\n.multiline\n    white-space: pre-wrap"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
