import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { FormsModule, NG_VALIDATORS, ReactiveFormsModule } from "@angular/forms";

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';


import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { CdkColumnDef } from '@angular/cdk/table';

import { TimelineComponent } from './timeline/timeline.component';
import { OverviewComponent } from './overview/overview.component';
import { LoginComponent } from './login/login.component';
import { DeviceComponent } from './device/device.component';
import { TimeRangePickerComponent } from './time-range-picker/time-range-picker.component';
import { DurationPipe } from './duration.pipe';
import { ConfirmComponent } from './confirm/confirm.component';
import { TimelineOffsetPipe } from './timeline/timeline-offset.pipe';
import { TimelineWidthPipe } from './timeline/timeline-width.pipe';
import { LogDisplayComponent } from './device/log-display/log-display.component';
import { MqttLogComponent } from './mqtt-log/mqtt-log.component';
import { FormatMomentPipe } from './format-moment.pipe';
import { DeviceListComponent } from './device-list/device-list.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { DeviceCreationFormComponent } from './device-creation-form/device-creation-form.component';
import { CustomerCreationFormComponent } from './customer-creation-form/customer-creation-form.component';
import { DeviceCommandDialogComponent } from './device-command-dialog/device-command-dialog.component';
import { DeviceProvisioningListComponent } from './device-provisioning-list/device-provisioning-list.component';


export function storageFactory(): OAuthStorage {
  return localStorage
}

@NgModule({
    declarations: [
        AppComponent,
        TimelineComponent,
        OverviewComponent,
        LoginComponent,
        DeviceComponent,
        TimeRangePickerComponent,
        DurationPipe,
        ConfirmComponent,
        TimelineOffsetPipe,
        TimelineWidthPipe,
        LogDisplayComponent,
        MqttLogComponent,
        FormatMomentPipe,
        DeviceListComponent,
        CustomerListComponent,
        DeviceCreationFormComponent,
        CustomerCreationFormComponent,
        DeviceCommandDialogComponent,
        DeviceProvisioningListComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatTableModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatSortModule,
        MatTooltipModule,
        MatDialogModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatCheckboxModule,
        MatCardModule,
        MatTabsModule,
        MatRadioModule,
        MatSlideToggleModule,
        MatDividerModule,
        MatGridListModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatMenuModule,
        MatListModule,
        NgxMaterialTimepickerModule,
        HttpClientModule,
        OAuthModule.forRoot({
            resourceServer: {
                sendAccessToken: true
            }
        })
    ],
    providers: [
        CdkColumnDef,
        { provide: OAuthStorage, useFactory: storageFactory }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
