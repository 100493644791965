import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { BackendService } from '../backend.service';
import { MqttLogDataSource } from './mqttLogDataSource';

@Component({
  selector: 'app-mqtt-log',
  templateUrl: './mqtt-log.component.html',
  styleUrls: ['./mqtt-log.component.sass']
})
export class MqttLogComponent implements OnInit, AfterViewInit {

  displayedColumns = ['timestamp', 'topic', 'message']
  dataSource: MqttLogDataSource;
  pageSize: number


  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(metricService: BackendService, paginatorCfg: MatPaginatorIntl) {
    this.dataSource = new MqttLogDataSource(metricService);
    paginatorCfg.previousPageLabel = "Previous Page (Ctrl + 🠔)";
    paginatorCfg.nextPageLabel = "Next Page (Ctrl + 🠖)";
  }


  filter(keyword: string) {
    this.dataSource.filter = keyword;
    this.dataSource.reload();
  }

  calculatePageSize(): number {
    const offset = 300;
    const rowHeight = 50;
    let height = (window.innerHeight - offset);
    return Math.round(height / rowHeight);
  }

  ngOnInit() {
    this.pageSize = this.calculatePageSize()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.init();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(e) {
    this.pageSize = this.calculatePageSize()
    this.dataSource.reload()
  }

  @HostListener('document:keydown.control.arrowright')
  onNavigateNext() {
    this.paginator.nextPage();
  }

  @HostListener('document:keydown.control.arrowleft')
  onNavigatePrevious() {
    this.paginator.previousPage();
  }

  @HostListener('document:keydown.control.space')
  onReload() {
    this.dataSource.reload();
  }

}
