export class CustomerCreationRequest {
    name: string
    customerId: string
    rootUserEmail?: string

    static parseData(data: any): CustomerCreationRequest {
        return {
            name: data["name"],
            customerId: data["customerId"],
            rootUserEmail: data["rootUserEmail"]
        }
    }
}