import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EMPTY } from 'rxjs';
import { finalize, map, mergeMap } from 'rxjs/operators';
import { BackendService } from '../backend.service';
import { ConfirmComponent, ConfirmDialogModel } from '../confirm/confirm.component';
import { Customer } from '../model/customer';
import { CustomerListDataSource } from './customerListDataSource';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.sass']
})
export class CustomerListComponent implements OnInit, AfterViewInit {
  dataSource: CustomerListDataSource
  displayedColumns = ['name', 'customerId', 'actions']

  constructor(private backend: BackendService, private dialog: MatDialog) {
    this.dataSource = new CustomerListDataSource(backend)
  }
  ngAfterViewInit(): void {
    this.dataSource.init()
  }

  clickRow(customer: Customer) {
    //TODO: navigate to customer detail view
  }

  createCustomer() {
    //TODO
  }

  deleteCustomer(customer: Customer) {
    const dialogData = new ConfirmDialogModel(`Folgenden Kunden löschen?`, customer.name);
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: dialogData
    });
    dialogRef.afterClosed().pipe(
      mergeMap(result => {
        if (result) {
          return this.backend.deleteCustomer$(customer)
        }
        return EMPTY
      }),
      finalize(() => this.dataSource.reload())
    ).subscribe();
  }


  ngOnInit() {
  }

}
