// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-mask {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  margin-top: 15%;
}

.login-field {
  width: 100%;
}

.footer {
  display: flex;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/app/login/login.component.sass"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gBAAA;EACA,WAAA;EACA,cAAA;EACA,eAAA;AACJ;;AACA;EACI,WAAA;AAEJ;;AAAA;EACI,aAAA;EACA,yBAAA;AAGJ","sourcesContent":[".login-mask\n    min-width: 150px\n    max-width: 500px\n    width: 100%\n    margin: 0 auto\n    margin-top: 15%\n\n.login-field\n    width: 100%\n\n.footer\n    display: flex\n    justify-content: flex-end\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
