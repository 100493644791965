import { Pipe, PipeTransform } from '@angular/core';
import moment, { Moment } from 'moment';

@Pipe({
  name: 'formatMoment'
})
export class FormatMomentPipe implements PipeTransform {
  readonly TS_FMT = "DD.MM.YYYY HH:mm:ss"

  transform(value: any, ...args: any[]): string {
    return moment(value).local().format(this.TS_FMT);
  }

}
