// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.range-select-form {
  display: flex;
  flex-direction: column;
}

.range-select-form-row {
  display: flex;
  flex-direction: row;
}

ngx-timepicker-field {
  height: 63px;
  display: inline-block;
  margin-inline-start: 1.5em;
}`, "",{"version":3,"sources":["webpack://./src/app/time-range-picker/time-range-picker.component.sass"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;;AACA;EACI,aAAA;EACA,mBAAA;AAEJ;;AAAA;EACI,YAAA;EACA,qBAAA;EACA,0BAAA;AAGJ","sourcesContent":[".range-select-form\n    display: flex\n    flex-direction: column\n\n.range-select-form-row\n    display: flex\n    flex-direction: row\n\nngx-timepicker-field\n    height: 63px\n    display: inline-block\n    margin-inline-start: 1.5em\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
