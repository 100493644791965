import { Injectable } from '@angular/core';
import { Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { OAuthService } from "angular-oauth2-oidc";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private oAuthService: OAuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (route.url.length && route.url[0].path == "login") {
      return true;
    }
    
    if (this.oAuthService.hasValidAccessToken()) {
      return true;
    } else {
      return this.router.parseUrl("/login");
    }
  }
  
}
