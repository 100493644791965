import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { BackendService } from '../backend.service';
import { CustomerCreationRequest } from '../model/customerCreationRequest';

@Component({
  selector: 'app-customer-creation-form',
  templateUrl: './customer-creation-form.component.html',
  styleUrls: ['./customer-creation-form.component.sass']
})
export class CustomerCreationFormComponent {
  isSubmitting = false
  snackBarRef?: MatSnackBarRef<SimpleSnackBar> = null;

  customerForm = this.formBuilder.group({
    name: [null, Validators.required],
    customerId: [null, Validators.required],
    rootUserEmail: [null, Validators.email],
  });

  get canSubmit() {
    return this.customerForm.valid
  }

  constructor(private formBuilder: UntypedFormBuilder, private backend: BackendService, private snackBar: MatSnackBar, private router: Router) { }


  submit(goBack = false) {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss()
      this.snackBarRef = null;
    }

    this.customerForm.disable()
    this.isSubmitting = true
    const request = CustomerCreationRequest.parseData(this.customerForm.value);
    this.backend.createCustomer$(request).pipe(
      finalize(() => {
        this.customerForm.enable()
        this.isSubmitting = false;
      })
    ).subscribe({
      error: err => this.onCustomerCreationError(err),
      complete: () => this.onCustomerCreated(goBack)
    });
  }

  onCustomerCreated(goBack: boolean) {
    this.snackBar.open("New customer created", '❌', { duration: 2000 })
    this.customerForm.reset()
    if (goBack)
      this.router.navigateByUrl("overview/customers")
  }

  onCustomerCreationError(e: any) {
    this.snackBarRef = this.snackBar.open("Error: " + e.message, '❌', { panelClass: 'snack-err' })
  }

}
