import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY, Observable } from "rxjs";
import { ignoreElements, map } from 'rxjs/operators';
import { DeviceInfo } from "./model/deviceInfo";
import { UserInfo } from './model/userInfo';
import moment from "moment";
import { environment } from "../environments/environment";
import { DeviceRealtimeInfo } from './model/deviceRealtimeInfo';
import { DeviceAlertConfig } from './model/deviceRuleConfig';
import { DeviceCommand } from './model/deviceCommand';
import { StatusPhase } from './model/statusPhase';
import { MqttLog, MqttLogEntry } from './model/mqttLogEntry';
import { Customer } from './model/customer';
import { DeviceFinalizationRequest } from './model/deviceCreationRequest';
import { CustomerCreationRequest } from './model/customerCreationRequest';
import { DeviceCommandResponse } from './model/deviceCommandResponse';
import { ProvisioningWorkflow } from './model/provisioningWorkflow';


class BackendUrls {
  static readonly overview = `${environment.backendUrl}/device/info`
  static readonly deviceInfo = `${environment.backendUrl}/device/{0}/info`
  static readonly deviceStatus = `${environment.backendUrl}/device/{0}/status`
  static readonly device = `${environment.backendUrl}/device/{0}`
  static readonly deviceFinalize = `${environment.backendUrl}/device/{0}/finalize`
  static readonly deviceCommand = `${environment.backendUrl}/device/{0}/command`
  static readonly devices = `${environment.backendUrl}/device`
  static readonly incompleteDevices = `${environment.backendUrl}/device/incomplete`
  static readonly timeline = `${environment.backendUrl}/device/{0}/status/timeline`
  static readonly realtimeOverview = `${environment.backendUrl}/comydo/metrics/overview/`
  static readonly userInfo = `${environment.backendUrl}/user/me`
  static readonly deviceAlertRuleConfig = `${environment.backendUrl}/device/{0}/alertconfig`
  static readonly mqttLogUrl = `${environment.backendUrl}/log/mqtt`
  static readonly customerList = `${environment.backendUrl}/customer`
  static readonly customer = `${environment.backendUrl}/customer/{0}`
}

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  readonly TS_FMT = "YYYY-MM-DDTHH:mm:ss.SSSS"

  constructor(private http: HttpClient) { }

  getOverview$(): Observable<DeviceInfo[]> {
    return this.http.get(BackendUrls.overview).pipe(
      map(data => data as DeviceInfo[])
    );
  }

  getDeviceTimeline$(deviceRefId: string, tsStart: moment.Moment, tsEnd: moment.Moment): Observable<StatusPhase[]> {
    let url = BackendUrls.timeline.fmt(deviceRefId);
    return this.http.get(url, {
      params: {
        start_ts: tsStart.format(this.TS_FMT),
        end_ts: tsEnd.format(this.TS_FMT)
      }
    }).pipe(map(data => data as StatusPhase[]));
  }

  getDeviceInfo$(deviceRefId: string): Observable<DeviceInfo> {
    let url = BackendUrls.deviceInfo.fmt(deviceRefId);
    return this.http.get(url).pipe(
      map(data => data as DeviceInfo)
    );
  }

  deleteDevice$(device: DeviceInfo | string): Observable<void> {
    let deviceRefId = "";
    if (device instanceof Object && 'deviceRefId' in device) {
      deviceRefId = device.deviceRefId;
    } else {
      deviceRefId = device;
    }
    return this.http.delete(BackendUrls.device.fmt(deviceRefId)).pipe(ignoreElements())
  }


  getDeviceRealtimeInfo$(deviceRefId: string): Observable<DeviceRealtimeInfo> {
    let url = BackendUrls.deviceStatus.fmt(deviceRefId);
    return this.http.get(url).pipe(
      map(data => data as DeviceRealtimeInfo)
    );
  }

  getUserInfo$() {
    return this.http.get(BackendUrls.userInfo).pipe(
      map(data => data as UserInfo)
    );
  }

  getDeviceAlertConfig$(deviceRefId: string): Observable<DeviceAlertConfig> {
    return this.http.get<DeviceAlertConfig>(BackendUrls.deviceAlertRuleConfig.fmt(deviceRefId)).pipe(
      map(d => {
        delete d["opsgenie_alert_state"];
        delete d["slack_alert_state"];
        return d;
      })
    );
  }

  updateDeviceAlertConfig$(config: DeviceAlertConfig) {
    console.log(config)
    return this.http.patch(BackendUrls.deviceAlertRuleConfig.fmt(config.deviceRefId), config, { responseType: "text" });
  }

  sendDeviceCommand$(deviceRefId: string, command: DeviceCommand) {
    let data = {
      "cmd": command,
    }
    return this.http.post<DeviceCommandResponse>(BackendUrls.deviceCommand.fmt(deviceRefId), data)
  }

  queryMqttLog$(offset: number, limit: number, keyword: string): Observable<MqttLog> {
    let params = {
      "offset": offset.toString(),
      "limit": limit.toString()
    };
    if (keyword)
      params["keyword"] = keyword;
    return this.http.get<MqttLogEntry[]>(BackendUrls.mqttLogUrl, { params: params, observe: 'response' }).pipe(
      map(resp => {
        return {
          page: resp.body,
          fullLength: parseInt(resp.headers.get("X-Paging-Total-Page"))
        }
      })
    )
  }

  getCustomers$(): Observable<Customer[]> {
    return this.http.get<Customer[]>(BackendUrls.customerList);
  }

  deleteCustomer$(customer: Customer): Observable<void> {
    return this.http.delete(BackendUrls.customer.fmt(customer.id)).pipe(ignoreElements())
  }

  finalizeDevice$(deviceRefId: string, request: DeviceFinalizationRequest): Observable<void> {
    return this.http.post(BackendUrls.deviceFinalize.fmt(deviceRefId), request).pipe(ignoreElements())
  }

  createDeviceProvisioningWorkflow$(): Observable<void> {
    return this.http.post(BackendUrls.devices, null).pipe(ignoreElements())
  }

  getProvisioningWorkflows$(): Observable<ProvisioningWorkflow[]> {
    return this.http.get<ProvisioningWorkflow[]>(BackendUrls.incompleteDevices)
  }

  createCustomer$(request: CustomerCreationRequest): Observable<void> {
    return this.http.post(BackendUrls.customerList, request).pipe(ignoreElements())
  }
}
