import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { MatPaginator } from "@angular/material/paginator";
import { Observable, Subject } from "rxjs";
import { BackendService } from "../backend.service";
import { MqttLogEntry } from "../model/mqttLogEntry";

export class MqttLogDataSource extends DataSource<MqttLogEntry> {
    paginator: MatPaginator
    dataStream = new Subject<MqttLogEntry[]>();
    filter: string


    constructor(private metricService: BackendService) {
        super()
    }
    connect(collectionViewer: CollectionViewer): Observable<MqttLogEntry[]> {
        return this.dataStream;
    }

    init() {
        this.paginator.page.subscribe(this.reload.bind(this));
        this.reload();
    }

    reload() {
        let limit = this.paginator.pageSize;
        let offset = this.paginator.pageIndex * this.paginator.pageSize;
        let query$ = this.metricService.queryMqttLog$(offset, limit, this.filter);
        query$.subscribe(l => {
            if (this.paginator.length != l.fullLength) {
                this.paginator.firstPage()
                this.paginator.length = l.fullLength;
            }
            this.dataStream.next(l.page);
        });
    }

    disconnect(collectionViewer: CollectionViewer): void {
    }

}