import "moment";
import moment from "moment";

export class DeviceFinalizationRequest {
    name: string;
    imei: string;
    iccid: string;
    simProvider: string;
    serialNr: string;
    creationInfo: CreationInfo;

    static parseData(data: any): DeviceFinalizationRequest {
        return {
            name: data["name"],
            imei: data["imei"],
            iccid: data["iccid"],
            simProvider: data["simProvider"],
            serialNr: data["serialNr"],
            creationInfo: CreationInfo.parseData(data["creationInfo"])
        }
    }
}

export class CreationInfo {
    customerId: string;
    locks: Lock[] = undefined;

    static parseData(data: any): CreationInfo {
        let locks = [];
        for (const lockData of data["locks"] as any[]) {
            locks.push(Lock.parseData(lockData))
        }
        if (!locks.length) {
            locks = undefined;
        }

        return {
            customerId: data["customerId"],
            locks: locks
        }
    }
}

export class Lock {
    activationDuration: moment.Duration;
    activationDelay: moment.Duration;
    lockGroupName?: string = undefined;
    lockId?: string = undefined;

    static parseData(data: any): Lock {
        return {
            activationDuration: moment.duration(data["activationDuration"], 'seconds'),
            activationDelay: moment.duration(data["activationDelay"], 'seconds'),
            lockGroupName: this.get(data, "lockGroupName"),
            lockId: this.get(data, "lockId")
        }
    }

    static get(data: any, name: string): string {
        let val = data[name];
        if (!val) {
            return undefined
        }
        return val
    }
}
