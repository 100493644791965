import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from "moment";
import { UntypedFormControl } from '@angular/forms';
import { state } from '@angular/animations';

@Component({
  selector: 'app-time-range-picker',
  templateUrl: './time-range-picker.component.html',
  styleUrls: ['./time-range-picker.component.sass']
})
export class TimeRangePickerComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) data) { 
    this.isRealtime = data.isRealtime;
    this.realtimeRangeHours = data.realtimeRangeHours;

    this.controlStart.setValue(data.startTs)
    this.controlEnd.setValue(data.endTs)

    this.startTime = data.startTs.format("HH:mm")
    this.endTime = data.endTs.format("HH:mm")
  }

  isRealtime: boolean
  realtimeRangeHours: number


  controlStart = new UntypedFormControl()
  controlEnd = new UntypedFormControl()

  startTime: string
  endTime: string

  ngOnInit() {
  }

  onTimeChanged(time: string, which: string) {
    if (which == "start") {
      this.startTime = time;
    } else if (which == "end") {
      this.endTime = time;
    }
  }

  getStartDatetime(): moment.Moment {
    let date: moment.Moment = this.controlStart.value;

    return moment.utc(date.format("YYYY-MM-DD") + "T" + this.startTime+ ":00", "YYYY-MM-DDTHH:mm")
  }

  getEndDatetime(): moment.Moment {
    let date: moment.Moment = this.controlEnd.value;

    return moment.utc(date.format("YYYY-MM-DD") + "T" + this.endTime+ ":00", "YYYY-MM-DDTHH:mm")
  }

  getResult() {
    return {
      isRealtime: this.isRealtime,
      realtimeRangeHours: this.realtimeRangeHours,
      startTs: this.getStartDatetime(),
      endTs: this.getEndDatetime(),
    }
  }

}
